// File to define common Gatsby browser & ssr function(s)
import React from 'react';
import { Breadcrumbs } from '@vp/breadcrumbs-react-component';
import { BoundedContent } from '@vp/swan';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { AuthProvider } from '@vp/digital-auth-lib';
import {
  RuntimeContextProvider,
  getClientEnvironment,
} from '@vp/digital-environment-lib';
import {
  PricingContextProvider,
  PricingContentProvider,
} from '@vp/digital-pricing-lib';
import { UrlContextProvider } from '@vp/digital-url-context-lib';
import {
  RootLayout,
  HtmlAttributes,
  MainLayout,
} from '@vp/digital-site-layout-lib';
import { BookendsWrapper } from '@vp/digital-bookends-lib';
import { Helmet } from 'react-helmet';
import { StyleKeys, ScriptKeys } from './src/swanKeys';

const CategoryPageWrapper = ({ pageContext, location, children }) => {
  const {
    locale,
    billingPeriods,
    directAccessClientId,
    trackingConfiguration,
    urlMetadata,
  } = pageContext;
  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <AuthProvider directAccessClientId={directAccessClientId}>
          <PricingContextProvider>
            <PricingContentProvider pricingContent={billingPeriods}>
              <UrlContextProvider location={location}>
                <RootLayout
                  swanStyleKeys={StyleKeys}
                  swanScriptKeys={ScriptKeys}
                >
                  <HtmlAttributes />

                  <BookendsWrapper
                    locale={locale}
                    renderMetaWith={Helmet}
                    trackingConfiguration={trackingConfiguration}
                  >
                    <MainLayout>
                      <BoundedContent>
                        <Breadcrumbs data={urlMetadata} />
                      </BoundedContent>
                      {children}
                    </MainLayout>
                  </BookendsWrapper>
                </RootLayout>
              </UrlContextProvider>
            </PricingContentProvider>
          </PricingContextProvider>
        </AuthProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { wrap },
  } = props;

  return wrap ? (
    <CategoryPageWrapper {...props}>{element}</CategoryPageWrapper>
  ) : (
    element
  );
};
