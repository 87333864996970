import { getLogger, getLibLoggingMetadata } from '@vp/digital-logging-lib';

var _a = require('../package.json'), name = _a.name, version = _a.version;
var getInvariantLogger = function (settings) {
    var meta = getLibLoggingMetadata(name, version, settings);
    return getLogger(meta);
};

var prefix = 'Invariant failed';
var invariantLogger = getInvariantLogger();
function invariant(condition, message) {
    if (condition) {
        return;
    }
    invariantLogger.error("".concat(prefix, ": ").concat(message || ''));
    throw new Error("".concat(prefix, ": ").concat(message || ''));
}

export { invariant };
