import React from 'react';
import { Footer, BOOKEND_TYPES, Header, BookendsMetaTag } from '@vp/esi-bookends';

var WrappedFooter = function (_a) {
    var tenant = _a.tenant, locale = _a.locale, trackingConfiguration = _a.trackingConfiguration, isSlimFooter = _a.isSlimFooter, _b = _a.hideFooter, hideFooter = _b === void 0 ? false : _b, children = _a.children;
    if (!hideFooter) {
        return (React.createElement(React.Fragment, null,
            children,
            React.createElement(Footer, { tenant: tenant, locale: locale, type: isSlimFooter ? BOOKEND_TYPES.SLIM : BOOKEND_TYPES.FULL, trackingConfiguration: trackingConfiguration })));
    }
    return React.createElement(React.Fragment, null, children);
};

var WrappedHeader = function (_a) {
    var tenant = _a.tenant, locale = _a.locale, trackingConfiguration = _a.trackingConfiguration, cartTenantOverride = _a.cartTenantOverride, isSlimHeader = _a.isSlimHeader, _b = _a.displayCartIcon, displayCartIcon = _b === void 0 ? true : _b, _c = _a.hideHeader, hideHeader = _c === void 0 ? false : _c, children = _a.children;
    if (!hideHeader) {
        return (React.createElement(React.Fragment, null,
            children,
            React.createElement(Header, { tenant: tenant, locale: locale, type: isSlimHeader ? BOOKEND_TYPES.SLIM : BOOKEND_TYPES.FULL, trackingConfiguration: trackingConfiguration, useMiniCart: displayCartIcon, MiniCartConfig: {
                    tenantDetails: cartTenantOverride,
                } })));
    }
    return React.createElement(React.Fragment, null, children);
};

var WrappedBookendsMetaTag = function (_a) {
    var tenant = _a.tenant, locale = _a.locale, isSlimHeader = _a.isSlimHeader, isSlimFooter = _a.isSlimFooter, renderWith = _a.renderWith;
    return (React.createElement(BookendsMetaTag, { renderWith: renderWith, tenant: tenant, locale: locale, headerType: isSlimHeader ? BOOKEND_TYPES.SLIM : BOOKEND_TYPES.FULL, footerType: isSlimFooter ? BOOKEND_TYPES.SLIM : BOOKEND_TYPES.FULL }));
};

var Bookends = function (_a) {
    var trackingConfiguration = _a.trackingConfiguration, locale = _a.locale, tenant = _a.tenant, cartTenantOverride = _a.cartTenantOverride, isSlimHeader = _a.isSlimHeader, isSlimFooter = _a.isSlimFooter, renderMetaWith = _a.renderMetaWith, _b = _a.displayCartIcon, displayCartIcon = _b === void 0 ? true : _b, _c = _a.hideFooter, hideFooter = _c === void 0 ? false : _c, _d = _a.hideHeader, hideHeader = _d === void 0 ? false : _d, children = _a.children;
    return (React.createElement(React.Fragment, null,
        React.createElement(WrappedBookendsMetaTag, { tenant: tenant, locale: locale, isSlimHeader: isSlimHeader, isSlimFooter: isSlimFooter, renderWith: renderMetaWith }),
        React.createElement(WrappedHeader, { trackingConfiguration: trackingConfiguration, tenant: tenant, locale: locale, cartTenantOverride: cartTenantOverride, isSlimHeader: isSlimHeader, displayCartIcon: displayCartIcon, hideHeader: hideHeader }),
        children,
        React.createElement(WrappedFooter, { tenant: tenant, locale: locale, trackingConfiguration: trackingConfiguration, isSlimFooter: isSlimFooter, hideFooter: hideFooter })));
};

var VISTAPRINT_BRAND = 'vistaprint';
var BookendsWrapper = function (_a) {
    var _b = _a.tenant, tenant = _b === void 0 ? VISTAPRINT_BRAND : _b, locale = _a.locale, trackingConfiguration = _a.trackingConfiguration, children = _a.children, isSlimHeader = _a.isSlimHeader, isSlimFooter = _a.isSlimFooter, _c = _a.hideFooter, hideFooter = _c === void 0 ? false : _c, _d = _a.hideHeader, hideHeader = _d === void 0 ? false : _d, _e = _a.displayCartIcon, displayCartIcon = _e === void 0 ? true : _e, cartTenantOverride = _a.cartTenantOverride, renderMetaWith = _a.renderMetaWith;
    return (React.createElement(Bookends, { trackingConfiguration: trackingConfiguration, tenant: tenant, locale: locale, isSlimHeader: isSlimHeader, isSlimFooter: isSlimFooter, hideFooter: hideFooter, hideHeader: hideHeader, displayCartIcon: displayCartIcon, cartTenantOverride: cartTenantOverride, renderMetaWith: renderMetaWith }, children));
};

export { Bookends, WrappedBookendsMetaTag as BookendsMetaTag, BookendsWrapper, WrappedFooter as Footer, WrappedHeader as Header };
